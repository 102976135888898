/* light and dark */

.nav-dark .principal-page {
    color: couleurs.$lightcolor;
}

.nav-dark .principal-page h1 {
    text-shadow: couleurs.$textshadow1dark;
    color: couleurs.$whitecolor;
}

.nav-light .principal-page h1 {
    text-shadow: couleurs.$textshadow1light;
    color: couleurs.$blackcolor;
}

.nav-light .principal-page {
    color: couleurs.$griscolor;
}

.nav-dark .midle-sideprincipal img {
    filter: drop-shadow(0px -6px 0px couleurs.$whitecolor);

}



/* light and dark */

.principal-page {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: 90vh;
    justify-content: center;
}

.left-sideprincipal h2 {
    font-size: 1.9vw;
    font-weight: 900;
}

.left-sideprincipal h1 {
    font-family: couleurs.$fontMonument;
    text-transform: uppercase;
    font-size: 4.5vw;
    margin-bottom: 0.01em;
    margin-top: 0.01em;
}


.left-sideprincipal {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    animation: couleurs.$animationStartLeftToRight;
}

.left-sideprincipal p {
    font-size: 1.26vw;
    line-height: 200%;
}

.midle-sideprincipal {
    width: 50%;
    position: relative;
    display: flex;
    animation: couleurs.$animationStartRightToLeft;
}

.midle-sideprincipal img {
    width: 100%;
    z-index: 3;
    transform: rotateY(180deg);
}

.right-sideprincipal {
    z-index: 10;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;

}

.box-image-left {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 15vh;
}

.box-image-right {
    width: 50%;
}

.box-image-right .box-image {
    margin-bottom: 5vh;
}


/* responsive */

@media screen and (max-width: 1280px) {
    .principal-page {
        height: 100vh;
        width: 95%;
    }

    .left-sideprincipal {
        width: 55%;

    }

    .left-sideprincipal p {
        font-size: 1.75vw;
    }

    .left-sideprincipal h1 {
        font-size: 5.8vw;
    }

    .left-sideprincipal h2 {
        font-size: 2.6vw;
    }

    .midle-sideprincipal {
        width: 40%;
    }

}

@media screen and (max-width: 780px) {

    .principal-page {
        flex-direction: column-reverse;
        align-items: center;
        height: 80vh;
    }

    .left-sideprincipal {
        width: 95%;
        text-align: center;
        animation: couleurs.$animationStartBotToTop;
    }

    .left-sideprincipal h1 {
        font-size: 8vw;
    }

    .left-sideprincipal h2 {
        font-size: 3.5vw;
    }

    .left-sideprincipal p {
        font-size: 1.4em;
    }

    .midle-sideprincipal img {
        border-radius: 50px;
    }

    .midle-sideprincipal {
        animation: couleurs.$animationStartBotToTop;
    }
}

@media screen and (max-width: 480px) {

    .left-sideprincipal p {
        font-size: 1em;
    }
}