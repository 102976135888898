/* dark and light  */

.nav-dark .works-landing h1 {
    text-shadow: couleurs.$textshadow1dark;
    color: couleurs.$whitecolor;
}

.nav-light .works-landing h1 {
    text-shadow: couleurs.$textshadow1light;
    color: couleurs.$blackcolor;
}



.nav-light .right {
    background: couleurs.$blackcolor;
}

.nav-light .left {
    background: couleurs.$blackcolor;
}

.nav-dark .right {
    background: couleurs.$whitecolor;
}

.nav-dark .left {
    background: couleurs.$whitecolor;
}

/* dark and light  */



.box {
    /* background: red; */
    width: 80em;
    height: 50vh;
    position: absolute;
}

.works {
    display: flex;
    flex-direction: column;
    gap: 100vh;
    overflow-x: hidden;
}

.works-landing {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    animation: couleurs.$animationStartBotToTopWithoutTransform;
}

.works-landing h1 {
    font-family: couleurs.$fontChillax;
    text-transform: uppercase;
    font-size: 4.5vw;
}

.right-box {
    /* we will position at the right of the screen without using absolute */
    position: relative;
    left: 100vw;
}

.left-box {
    position: relative;
    left: -80em;
}

/* scroll down effect */
.arrow-down {
    width: 60px;
    height: 40px;
    margin: 0 0 0 -30px;
    position: absolute;
    left: 50vw;
    bottom: 0px;
    animation: arrow 0.5s 1s infinite ease-out alternate;

}


.left {
    position: absolute;
    height: 10px;
    width: 40px;
    transform: rotate(240deg);
    top: 10px;
    left: 1.5em;
    border-radius: 4px;
    transform-origin: 5px 50%;
    animation: leftArrow 0.5s 1s infinite ease-out alternate;
}

.right {
    position: absolute;
    height: 10px;
    width: 40px;
    transform: rotate(-60deg);
    top: 10px;
    left: 1.5em;
    border-radius: 4px;
    transform-origin: 5px 50%;
    animation: rightArrow 0.5s 1s infinite ease-out alternate;
}

@media screen and (max-width: 1300px) {

    // 80em to 60em
    .box {
        width: 90vw;
    }

    .left-box {
        left: -90vw;
    }

}

@media screen and (max-width: 780px) {



    .works-landing h1 {
        font-size: 7.5vw;
    }

    .left {
        position: absolute;
        height: 10px;
        width: 30px;
        transform: rotate(240deg);
        top: 10px;
        left: 1.5em;
        border-radius: 4px;
        transform-origin: 5px 50%;
        animation: leftArrow 0.5s 1s infinite ease-out alternate;
    }

    .right {
        position: absolute;
        height: 10px;
        width: 30px;
        transform: rotate(-60deg);
        top: 10px;
        left: 1.5em;
        border-radius: 4px;
        transform-origin: 5px 50%;
        animation: rightArrow 0.5s 1s infinite ease-out alternate;
    }

    .box {
        width: 95vw;
    }

    .left-box {
        left: -95vw;
    }

}






@keyframes arrow {
    0% {
        bottom: 0px;
    }

    100% {
        bottom: 40px;
    }
}


@keyframes leftArrow {
    0% {}

    100% {
        transform: rotate(225deg);
    }
}

@keyframes rightArrow {
    0% {}

    100% {
        transform: rotate(-45deg);
    }
}