/* dark and light */

.nav-light .footeremail button:hover span {
  box-shadow: couleurs.$boxshadowdark;
}

.nav-dark .footeremail button:hover span {
  box-shadow: couleurs.$boxshadowlight;
}

.nav-light .footeritem:hover {
  background: couleurs.$blackcolor;
  box-shadow: couleurs.$boxshadowdark;
}

.nav-dark .footeritem:hover {
  background: couleurs.$whitecolor;
  box-shadow: couleurs.$boxshadowlight;
}

.nav-dark .footeritem:hover img {
  filter: invert(0);
}





/* dark and light */


footer {
  position: absolute;
  bottom: 3%;
  left: 5%;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  animation: couleurs.$animationStartLeftToRight;
}

.footeremail {
  position: relative;
}

.footeremail span {
  display: none;
}

.footeremail button:hover span {
  display: inline-block;
  position: absolute;
  top: -5.5em;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: white;
  color: couleurs.$blackcolor;
  border-radius: 1em;
  white-space: nowrap;
  padding: 0.7vw;
  font-size: 100%;
  /* font-weight: 600; */
  animation: couleurs.$animationSlideBotToTopCreation , couleurs.$animationFlottement ;
}

//pour les utilisateurs de safari on met left a 17%
@supports (-webkit-touch-callout: none) {
  .footeremail button:hover span {
    left: 0%;
  }
}

.footeremail button span::before {
  content: "";
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  border: 0.6rem solid transparent;
  border-top: 1.5rem solid rgb(255, 255, 255);
}

.footeritem {
  background: couleurs.$bluecolor;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

}

.footeritem img {
  width: 2rem;
  height: 2rem;
  filter: invert(1);
  /* transition: all 0.2s ease-in-out; */
}

.footeremail button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  padding: 0.75rem;
}

footer .footeritem a {
  display: flex;
  padding: 0.75rem;
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 780px) {
  footer {
    position: initial;
    animation: couleurs.$animationStartTopToBot;
  }

  .footeremail button:hover span {
    z-index: 10;
    padding: 0.9em;

  }
}

@media screen and (max-width: 480px) {
  .footeritem img {
    width: 1.5rem;
    height: auto;
  }


}