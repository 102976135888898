@use 'couleurs.scss';

@import "Components/NavBar/Navbar.scss";
@import "Components/PrincipalPage/PrincipalPage.scss";
@import "Components/BoxImage/BoxImage.scss";
@import "Components/Footer/Footer.scss";
@import "Components/Works/Works.scss";
@import "Components/WorkBox/WorkBox.scss";
@import "Components/NavbarPoints/NavbarPoints.scss";
@import "Components/AppearOnScroll/AppearOnScroll.scss";
@import "Components/Contact/Contact.scss";
@import "Components/AboutMe/AboutMe.scss";
@import "Components/SafariModal/SafariModal.scss";


body {
  margin: 0;
  padding: 0;
  font-family: couleurs.$fontGotham ;
  /* caret-color: transparent; */
}

body::-webkit-scrollbar {
  display: none;
}

/* dark-light */
.nav-light {
  background: couleurs.$lightcolorbackground;
  min-height: 100vh;
  /* transition: 1s; */
}

.nav-dark {
  background: couleurs.$darktcolorbackground;
  min-height: 100vh;
  /* transition: 1s; */
  // overflow: hidden;
}

.homeback-white {
  background: couleurs.$lighthomebackground;
  background-size: 50%;

}

.homeback-black {
  background-image: couleurs.$darkhomebackground;
  background-size: 50%;
  /* 
  background: couleurs.$blackcolor; */
  /*  background: couleurs.$darkhomebackground; */
}



/* dark-light */