/* dark and light */

.nav-light .box-image img {
    border: solid 5px rgb(255, 255, 255);
}

.nav-dark .box-image img {
    border: solid 5px rgb(0, 0, 0);
}

.nav-light .box-image p {
    background: rgb(255, 255, 255);
}

.nav-dark .box-image p {
    background: rgb(0, 0, 0);
}




/* dark and light */

.box-image img {
    border-radius: 15px;
    box-shadow: couleurs.$boxshadowdark;
}

.box-image p {
    padding: 1em;
    border-radius: 15px;
    text-align: center;
    box-shadow: couleurs.$boxshadowdark;
    position: relative;
    font-weight: 900;
    /* Ajoute une position relative à l'élément p */
}

/*  on va créer un trait noir que l'on va positionner au dessus du texte  */

.box-image p::before {
    content: "";
    display: block;
    position: absolute;
    top: -5%;
    left: 41%;
    width: 20%;
    height: 10%;
    background-color: rgb(0, 0, 0);
    border-radius: 35px;
}

.box-image p::after {
    content: "";
    display: block;
    position: absolute;
    top: -15%;
    left: 49%;
    width: 4%;
    height: 30%;
    background-color: rgb(0, 0, 0);
    border-radius: 50vw;
}




.box-image {
    display: flex;
    flex-direction: column;
    width: 100%;
}