.slider-container {
    .awssld__bullets {
        bottom: 0;
    }

    .awssld__content {
        background: transparent;
    }

}


.PrincipalAboutMe {


    --lt-color-gray-100: #f8f9fc;
    --lt-color-gray-200: #f1f3f9;
    --lt-color-gray-300: #dee3ed;
    --lt-color-gray-400: #c2c9d6;
    --lt-color-gray-500: #8f96a3;
    --lt-color-gray-600: #5e636e;
    --lt-color-gray-700: #2f3237;
    --lt-color-gray-800: #1d1e20;
    --lt-color-gray-900: #111213;
    --lt-shadowDefault: 0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04);
    --lt-shadowActive: 0 0 8px -2px rgba(0, 0, 0, 0.1), 0 6px 20px -3px rgba(0, 0, 0, 0.2);
    --lt-color-white: #fff !important;
    --lt-color-black: #111213 !important;
    --lt-color-transparent: rgba(255, 255, 255, 0) !important;
    --lt-color-background-light: var(--lt-color-gray-100) !important;
    --lt-color-background-default: var(--lt-color-gray-200) !important;
    --lt-color-background-dark: var(--lt-color-gray-300) !important;
    --lt-color-border-light: var(--lt-color-gray-200) !important;
    --lt-color-border-default: var(--lt-color-gray-300) !important;
    --lt-color-border-dark: var(--lt-color-gray-400) !important;
    --lt-color-text-very-light: var(--lt-color-gray-500) !important;
    --lt-color-text-light: var(--lt-color-gray-600) !important;
    --lt-color-text-default: var(--lt-color-gray-700) !important;
    --lt-color-text-dark: var(--lt-color-gray-800) !important;
    --lt-color-overlay-default: #fff !important;
    --lt-color-overlay-dark: #fff !important;
    --lt-color-overlay-transparent: rgba(0, 0, 0, 0.1) !important;
    --lt-shadow-website-overlay: 0 0 7px 0 rgba(0, 0, 0, 0.3) !important;
    --header-height: 3rem;
    --hue: 0;
    --sat: 0%;
    --title-color: hsl(var(--hue), var(--sat), 20%);
    --title-color-dark: hsl(var(--hue), var(--sat), 0%);
    --text-color: hsl(var(--hue), var(--sat), 46%);
    --body-color: hsl(var(--hue), var(--sat), 98%);
    --container-color: #fff;
    --body-font: "Poppins", sans-serif;
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --tiny-font-size: 0.625rem;
    --font-normal: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
    --background: #f3a683;
    --base: #303952;
    --accent: #786fa6;
    --shadow: #e77f67;
    --duration: 10s;
    --size: 80vw;
    --characters: 14;
    --font-size: 22px;
    --delay-character: 0.2s;
    --text-color-hover: #ff8489;
    --swiper-theme-color: #007aff;
    -webkit-font-smoothing: antialiased;

    /*! CSS Used from: https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css */
    .bx {
        font-family: boxicons !important;
        font-weight: 400;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        text-rendering: auto;
        display: inline-block;
        text-transform: none;
        //speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .bx-award:before {
        content: "\e9bf";
    }

    .bx-briefcase-alt:before {
        content: "\ea01";
    }

    .bx-support:before {
        content: "\ec35";
    }

    /*! CSS Used from: https://www.bbkx.live/static/css/main.cad3a218.css */
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    h2,
    h3 {
        color: #333;
        color: var(--title-color);
        font-weight: var(var(--font-semi-bold));
    }

    a {
        text-decoration: none;
    }

    .section {
        padding: 6rem 0 2rem;
    }

    .section__title {
        color: #333;
        color: var(--title-color);
        font-size: 2.25rem;
        font-size: var(--h1-font-size);
    }

    .section__subtitle {
        display: block;
        font-size: .875rem;
        font-size: var(--small-font-size);
        margin-bottom: 4rem;
    }

    .section__subtitle,
    .section__title {
        text-align: center;
    }

    .container {
        margin-left: auto;
        margin-right: auto;
        max-width: 968px;
    }

    .grid {
        grid-gap: 1.5rem;
        display: grid;
        gap: 1.5rem;
    }

    .button {
        background-color: couleurs.$bluecolor;
        border-radius: 1rem;
        color: #fff;
        color: var(--container-color);
        display: inline-block;
        font-weight: 500;
        font-weight: var(--font-medium);
        padding: 1.25rem 2rem;

    }

    .button:hover {
        background-color: couleurs.$blackcolor;
        transition: all .3s;
    }

    .button__icon {
        margin-left: .5rem;
        margin-left: var(--mb-0-5);
    }

    .button--flex {
        align-items: center;
        display: inline-flex;
    }

    @media screen and (max-width:992px) {
        .container {
            margin-left: 1.5rem;
            margin-left: var(--mb-1-5);
            margin-right: 1.5rem;
            margin-right: var(--mb-1-5);
        }

        .button {
            padding: 1rem 1.75rem;
        }

        .button__icon {
            height: 22px;
            width: 22px;
        }
    }

    @media screen and (max-width:768px) {
        .section {
            padding: 2rem 0 4rem;
        }

        .section__subtitle {
            margin-bottom: 3rem;
            margin-bottom: var(--mb-3);
        }
    }

    @media screen and (max-width:350px) {
        .container {
            margin-left: 1rem;
            margin-left: var(--mb-1);
            margin-right: 1rem;
            margin-right: var(--mb-1);
        }
    }

    .about__container {
        align-items: center;
        -webkit-column-gap: 4rem;
        column-gap: 4rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .about__info {
        gap: .5rem;
        grid-template-columns: repeat(3, 140px);
        margin-bottom: var(--mb-2);
    }

    .about__box {
        background-color: var(--container-color);
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: .75rem;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
        padding: 1rem 1.25rem;
        text-align: center;
    }

    .about__icon {
        color: var(--title-color);
        font-size: 1.5rem;
        margin-bottom: var(--mb-0-5);
    }

    .about__title {
        font-size: var(--small-font-size);
        font-weight: var(--font-medium);
    }

    .about__subtitle {
        font-size: var(--tiny-font-size);
    }

    .about__description {
        margin-bottom: var(--mb-2-5);
        padding: 0 4rem 0 0;
    }

    @media screen and (max-width:992px) {
        .about__container {
            grid-template-columns: 1fr;
            row-gap: 2.5rem;
        }

        .about__box {
            padding: .75rem .5rem;
        }

        .about__data {
            text-align: center;
        }

        .about__info {
            justify-content: center;
        }

        .about__description {
            margin-bottom: 2rem;
            padding: 0 5rem;
        }
    }

    @media screen and (max-width:576px) {
        .about__info {
            grid-template-columns: repeat(3, 1fr);
        }

        .about__description {
            padding: 0;
        }
    }

    @media screen and (max-width:350px) {
        .about__info {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .frame {
        background: url(couleurs.$imageSemiPro) no-repeat 50%;
        background-size: cover;
        height: 80vw;
        height: var(--size);
        margin: 0 auto;
        max-height: 450px;
        max-width: 400px;
        position: relative;

        width: 80vw;
        width: var(--size);
    }



    /*! CSS Used fontfaces */
    @font-face {
        font-family: boxicons;
        font-weight: 400;
        font-style: normal;
        src: url(https://unpkg.com/boxicons@2.1.4/fonts/boxicons.eot);
        src: url(https://unpkg.com/boxicons@2.1.4/fonts/boxicons.eot) format('embedded-opentype'), url(https://unpkg.com/boxicons@2.1.4/fonts/boxicons.woff2) format('woff2'), url(https://unpkg.com/boxicons@2.1.4/fonts/boxicons.woff) format('woff'), url(https://unpkg.com/boxicons@2.1.4/fonts/boxicons.ttf) format('truetype'), url(https://unpkg.com/boxicons@2.1.4/fonts/boxicons.svg?#boxicons) format('svg');
    }


    .qualification {
        min-width: 1200px;
        // on force le fait qu'il n'y ait pas de transition
        transition: none;
        animation: none;
    }

    @media (min-width: 780px) and (max-height: 1080px) {
        .qualification {
            transform: scale(0.75);
        }

        .Myskills {
            transform: scale(0.85);
        }

        .AboutMeCard section {
            transform: scale(0.75);
        }


    }

    // while under 1200px we disable the min-width
    @media screen and (max-width: 1170px) {
        .qualification {
            min-width: 0;
        }
    }

    .vertical-timeline::before {
        background: black;
    }

    .vertical-timeline-element-content {
        filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    }


    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
        font-size: 16px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .qualification__title {
        margin-bottom: 15px;
    }

    @media screen and (max-width: 1024px) {
        .awssld__content {
            overflow-y: scroll;
        }

        .qualification {
            margin-top: 25vh;
            margin-bottom: 25vh;

        }

        .Myskills .section__subtitle {
            margin-bottom: 1rem;
        }


    }

    // media screen 
    @media screen and (max-width: 780px) {


        .awssld__content {
            margin-top: 10vh;
            overflow-y: scroll;
        }



        .about__data .button {
            margin-bottom: 40vh;
        }

        .AboutMeCard .section__title {
            margin-top: 40vh;
        }




    }

    @media screen and (max-width: 480px) {
        .AboutMeCard .section__title {
            margin-top: 550px;
        }

        .qualification {
            margin-top: 370px;
        }
    }


    /* Media Query pour les écrans dont la hauteur est inférieure ou égale à 1080px */
    @media screen and (max-height: 1080px) {
        .qualification .section__subtitle {
            margin-bottom: 10px;
        }

        .qualification .vertical-timeline--two-columns {
            padding: 0;
            padding-top: 2em;
        }



        .qualification .vertical-timeline-element {
            margin: 0.5em 0;
        }

        .qualification .vertical-timeline-element-date {
            padding-bottom: 0;

        }
    }

    /* Media Query pour les écrans dont la hauteur est inférieure ou égale à 900px et une width supérieur 1200px */
    @media screen and (max-height: 900px) and (min-width: 1200px) {
        .qualification {
            margin-top: 11vh;

        }

    }

    .qualification .qualification__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .skills__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        /* Ajout pour étirer les boîtes à la même hauteur */
    }

    .skills__content {
        flex: 0 0 calc(50% - 20px);
        display: flex;
        /* Ajout pour adapter la mise en page à l'intérieur */
        flex-direction: column;
        /* Ajout pour adapter la mise en page à l'intérieur */
        /*  margin: 0 10px 20px; */
        padding: 20px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background-color: #ffffff;
        min-width: 750px;
    }

    .skills__title {
        font-size: 24px;
        /* Augmentation de la taille du texte */
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
    }

    .skills__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        column-count: 4;
        column-gap: 20px;
        flex-grow: 1;
        /* Ajout pour occuper tout l'espace disponible */
    }

    .skills__item {
        display: flex;
        align-items: center;
        font-size: 18px;
        /* Augmentation de la taille du texte */
        margin-bottom: 10px;
        break-inside: avoid;

    }

    .skills__icon {
        margin-right: 10px;
        font-size: 24px;
        /* Augmentation de la taille des icônes */
        color: couleurs.$bluecolor;
    }


    @media (max-width: 780px) {
        .skills__content {
            flex: 0 0 100%;
            margin-bottom: 20px;
            min-width: 0px;
        }

        .skills__list {
            column-count: 1;
        }

        .Myskills .section__title {
            margin-top: 1100px;
        }

        .skills__container {
            margin-bottom: 20vh;
        }
    }

    // si la width est supérieur a  780 et la height inférieur à 1080


}

.nav-light .PrincipalAboutMe .section__title {
    color: couleurs.$blackcolor;
}

.nav-light .PrincipalAboutMe .section__subtitle {
    color: couleurs.$blackcolor;
}

.nav-light .PrincipalAboutMe .skills__title {
    color: couleurs.$blackcolor;
}

.nav-light .PrincipalAboutMe .skills__item {
    color: couleurs.$blackcolor;
}



.nav-dark .PrincipalAboutMe .section__title {
    color: couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe .section__subtitle {
    color: couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe .skills__title {
    color: couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe .skills__item {
    color: couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe .skills__content {
    background-color: couleurs.$griscolor2;
}

.nav-dark .PrincipalAboutMe .frame {
    border: 2px solid couleurs.$griscolor2;
    box-shadow: inset 0 0 0 20px couleurs.$griscolor2;
}

.nav-light .PrincipalAboutMe .frame {
    border: 2px solid couleurs.$whitecolor;
    box-shadow: inset 0 0 0 20px couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .frame:hover {
    border: 2px solid couleurs.$whitecolor;
    box-shadow: inset 0 0 0 0 couleurs.$whitecolor;
    transition: all .3s ease;
}

.nav-dark .PrincipalAboutMe .frame:hover {
    border: 2px solid couleurs.$griscolor2;
    box-shadow: inset 0 0 0 0 couleurs.$griscolor2;
    transition: all .3s ease;
}

.nav-light .PrincipalAboutMe .about__description {
    color: couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .about__description {
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .about__box {
    background-color: couleurs.$whitecolor;
    color: couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .about__box {
    background-color: couleurs.$griscolor2;
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .about__icon {
    color: couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .about__icon {
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .about__title {
    color: couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .about__title {
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .button:hover {
    background-color: couleurs.$blackcolor;
}


.nav-dark .PrincipalAboutMe .button:hover {
    background-color: couleurs.$whitecolor;
    color: couleurs.$blackcolor;

    .button__icon {
        filter: invert(1);
    }
}

.nav-light .PrincipalAboutMe .qualification {
    color: couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .qualification {
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .qualification__title {
    color: couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .qualification__title {
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe .vertical-timeline-element-content {
    background-color: couleurs.$whitecolor;
    color: couleurs.$blackcolor;
    box-shadow: 0 3px 0 couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe .vertical-timeline-element-content {
    background-color: couleurs.$griscolor2;
    color: couleurs.$whitecolor;
    box-shadow: 0 3px 0 couleurs.$blackcolor;
}

.nav-dark .PrincipalAboutMe .vertical-timeline-element-content img {
    filter: invert(1);
}

.nav-light .PrincipalAboutMe .vertical-timeline-element-content-arrow {
    border-right-color: couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe .vertical-timeline-element-content-arrow {
    border-right-color: couleurs.$griscolor2;
}

.nav-dark .PrincipalAboutMe .vertical-timeline::before {
    background: couleurs.$whitecolor;
}

.nav-dark .PrincipalAboutMe h3 {
    color: couleurs.$whitecolor;
}

.nav-light .PrincipalAboutMe h3 {
    color: couleurs.$blackcolor;
}