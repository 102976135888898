/* dark and light */




/* dark and light */





.NavbarPoints {
    display: flex;
    align-items: center;

}

.boutonZone {
    display: flex;
    align-items: center;
}

.ButtonWithText {
    position: relative;
    display: flex;
    align-items: center;
}

.NavbarPoints button {
    color: couleurs.$whitecolor;
    background: couleurs.$blackcolor;
    // we add bottom shadow box to see the button
    box-shadow: 0px 0px 0px 0.1vw couleurs.$whitecolor;
    width: 3.8em;
    height: 3.8em;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
}

.NavbarPoints .stick {
    background: couleurs.$blackcolor;
    border-top: 0.1vw solid couleurs.$whitecolor;
    margin-top: 0.6vw;
    border-bottom: 0.1vw solid couleurs.$whitecolor;
    z-index: 1;
    width: 2em;
    height: 0.5em;
    display: block;
    color: couleurs.$blackcolor;
    z-index: 5;
}

// hover scale effect
.NavbarPoints button:hover {
    z-index: 10;
    transform: scale(1.2);
    transition: 0.2s;
    background: couleurs.$bluecolor;
}

.ButtonWithText .textOverButton {
    position: absolute;
    z-index: 10;
    top: -4vh;
    color: couleurs.$bluecolor;
    font-size: 170%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    text-shadow: couleurs.$textshadow1lightsmall;
    border-radius: 10px;
    cursor: default;
    text-transform: uppercase;
    font-family: couleurs.$fontChillax;
}

@media screen and (max-width: 1980px) {

    .NavbarPoints .stick {
        border-top: 0.1vw solid #fff;
        margin-top: 0.9vw;
    }


}

@media screen and (max-width: 1300px) {

    .NavbarPoints button {
        width: 4vw;
        height: 4vw;
    }

    .NavbarPoints .stick {
        width: 3vw;
        height: 0.7vw;
        border-top: 0.1vw solid #fff;
        margin-top: 1.6vw;
    }

    .ButtonWithText .textOverButton {
        font-size: 170%;
        top: -4vh;
    }

}

@media screen and (max-width: 780px) {

    // we display none the navbarpoint
    .NavbarPoints {
        display: none;
    }

}