/* dark and light  */

.nav-light .contact {
    box-shadow: couleurs.$boxshadowdark;
    background: couleurs.$whitecolor;
    color: couleurs.$blackcolor;


    .inputBox button {
        background: couleurs.$bluecolor;
        color: couleurs.$whitecolor;

        transition: all 0.2s ease-in-out;
    }

    .inputBox button:hover {
        background: couleurs.$blackcolor;
        transition: all 0.2s ease-in-out;
    }

}

.nav-dark .contact {
    background: couleurs.$griscolor2;
    color: couleurs.$whitecolor;

    .inputBox button {
        background: couleurs.$bluecolor;
        color: couleurs.$whitecolor;
        transition: all 0.2s ease-in-out;
    }

    .inputBox button:hover {
        background: couleurs.$whitecolor;
        color: couleurs.$blackcolor;
        transition: all 0.2s ease-in-out;
    }
}

@media screen and (max-width: 768px) {

    // on change le background de contact
    .nav-dark .contact {
        background: rgb(couleurs.$blackcolor, 0.2);
    }

    .nav-light .contact {
        background: rgb(couleurs.$whitecolor, 0.8);
    }
}

/* dark and light  */


.center {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.contact {
    background: couleurs.$whitecolor;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: couleurs.$boxshadowdark;
    border-radius: 1em;
    margin-bottom: 5vh;
    padding-bottom: 2em;
    animation: startRightToLeft 0.4s ease-in-out;

    h1 {
        margin-bottom: 1.5em;
        font-size: 2.5em;
    }


    #testi button:hover span {
        animation: slideBotToTopCreation2 0.4s, flottement 2s ease-in-out infinite;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em;




        .inputBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1em;
            width: 50em;




            .input-zone {
                display: flex;
                flex-direction: column;
                width: 100%;

                input {
                    /*   border-radius: 500px; */
                    border: none;
                    background: couleurs.$grisInput;
                    border-radius: 10px;
                    padding-right: 2em;
                    padding-left: 2em;
                    padding-top: 1em;
                    padding-bottom: 1em;

                    font-size: 0.9em;
                    min-width: 0;

                    //placeholder color
                    &::placeholder {
                        color: #828282;
                        font-size: 1.2em;
                    }
                }

                //textarea {
                textarea {
                    width: 100%;
                    margin: 0 auto;
                    padding: 0;
                    border-radius: 10px;
                    border: none;
                    padding: 2em;
                    box-sizing: border-box;
                    padding-top: 1.5em;
                    padding-bottom: 1.5em;
                    font-size: 1.2em;
                    min-width: 0;
                    height: 8em;
                    background: couleurs.$grisInput;

                    //placeholder color
                    &::placeholder {
                        color: #828282;
                        font-size: 1.2em;
                    }
                }


            }

            button {
                width: 40%;
                margin: 0 auto;
                margin-top: 1em;
                padding: 1%;
                border-radius: 500px;
                border: none;
                padding-left: 2em;
                padding-right: 2em;
                font-size: 1.2em;
                min-width: 0;
                font-weight: 600;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                height: 2.5em;
            }

        }
    }

    .contact-Footer {
        display: flex;
        gap: 0.75em;

        a {
            padding: 0.75rem;
            display: flex;
            align-items: center;
        }
    }

    .footeritem img {
        margin: 0;
    }

}

// responsive

@media screen and (max-width: 1024px) {

    // we change the 50 em to 90% to make it responsive
    .contact {
        .contact-form {
            .inputBox {
                width: 80vw;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .center {
        align-items: normal;
        overflow-x: hidden;

        .contact {
            margin: 0;
            padding: 0;
            border-radius: 0;
            width: 100%;

            .contact-form {
                width: 90%;
                padding: 0;

                .inputBox {
                    width: 100%;

                    button {
                        width: 50%;
                    }
                }
            }
        }


    }
}