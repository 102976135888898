.AppearOnScroll {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 1em;
    // visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

}