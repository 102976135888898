/* dark and lights */

.nav-light .WorkBox {

    box-shadow: couleurs.$boxshadowdark;
    background: couleurs.$whitecolor;
    color: couleurs.$blackcolor;
}

.nav-dark .WorkBox {

    /* box-shadow: couleurs.$boxshadowlight; */
    background: couleurs.$griscolor2;
    color: couleurs.$whitecolor;
}




/* dark and lights */



.WorkBox {
    display: flex;
    align-items: center;
    gap: 2em;
    width: 80em;
    border-radius: 25px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
}

.WorkBox:hover {
    transform: scale(1.05);
    cursor: pointer;
    transition: all 0.5s ease-in-out;

}

.WorkBox-text {
    width: 50%;
    margin-right: 2em;
    text-align: justify;
}

.WorkBox-text h2 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 0.5em;

}

.WorkBox-text h3 {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.WorkBox-text p {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 1em;
}

.WorkBox-tech {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    margin-bottom: 1em;
}

.WorkBox-tech span {
    padding: 0.5em 1em;
    border-radius: 25px;
    background: rgba(couleurs.$bluecolor, 0.2);
    color: couleurs.$bluecolor;
    font-size: 0.9em;
    font-weight: 700;
}



.WorkBox img {

    height: 50vh;
    width: 50%;
    object-fit: cover;
    /* filter: grayscale(100%); */
}

#width100 {
    width: 100%;
    // pour avoir une meilleur visibilité du texte au dessus de l'image on va l'assombrir
    filter: brightness(0.5);

    object-fit: contain;
    background: #FCE1C4;



}

#absolutecenter {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    font-family: couleurs.$fontChillax;
    text-transform: uppercase;
    font-size: 4.9vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    -webkit-text-stroke: 1px white;
    width: 10em;
    /* Contour blanc */
}

#relative {
    position: relative;
}

// responsive

@media screen and (max-width: 1300px) {
    .WorkBox {
        width: 90vw;
    }

    .WorkBox img {
        width: 45%;
        border-radius: 25px;
        margin-left: 1.5em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    // tech 
    .WorkBox-tech {
        font-size: 0.8em;
    }

    .WorkBox-text {
        width: 50%;
        margin-right: 2em;
        text-align: justify;
    }

    #absolutecenter {
        font-size: 5.5vw;

    }

    #width100 {
        margin: 0 auto;

    }

}



@media screen and (max-width: 780px) {
    .WorkBox {
        width: 95vw;
        height: 85vh;
        flex-direction: column;
        align-items: center;
    }

    .WorkBox img {
        width: 100%;
        height: 15vh;
        // no border-radius on bottom
        border-radius: 25px 25px 0 0;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .WorkBox-text {
        width: 90%;
        margin-right: 0;
        text-align: justify;

        h2 {
            text-align: center;
            font-size: 4.5vw;
        }

        p {
            font-size: 3.1vw;
        }

        span {
            font-size: 3vw;
        }

    }

    #width100 {
        height: 80vh;
    }

    #absolutecenter {
        font-size: 10.5vw;
        // we center it 
        top: 30vh;

    }


}

// 480px

@media screen and (max-width: 480px) {


    /*     .WorkBox img {
        height: 20vh;
    } */


    .WorkBox-text h2 {
        font-size: 200%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .WorkBox-text p {
        font-size: 100%;
    }

}