/* Dark and light mode */

.nav-dark .Menu-Items a {
  color: couleurs.$lightcolor;
}

.nav-light .Menu-Items a {
  color: couleurs.$griscolor;
}



/* Dark and light mode */

.Menu-Items::after {
  content: "";
  width: 0%;
  height: 4px;
  background: couleurs.$bluecolor;
  border-radius: 20px;
  display: block;
  margin: auto;
  margin-top: 5px;

  transition: 0.3s;

}

.Menu-Items:hover::after {
  width: 50%;
}

.Menu-Items.active::after {
  width: 50%;
  background: couleurs.$bluecolor;
}

.Menu-Items.active a {
  color: couleurs.$bluecolor;
}

.Menu-Items.active img {
  filter: couleurs.$redfilter;
}

.Menu-Items {
  list-style-type: none;
}

.Menu-Items a {
  text-decoration: none;
  font-size: 150%;
  transition: 0.3s;
}

.Menu-Items a:hover {
  color: couleurs.$bluecolor;

}

#talk {
  background-color: couleurs.$bluecolor;
  color: white;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 15px;
  font-size: 120%;
  border: none;
  cursor: pointer;
  box-shadow: couleurs.$boxshadowblue3;
  transition: 0.3s;
  animation: bounce 1s ease-in-out infinite;
  text-decoration: none;
  font-weight: 900;
}

#talk:hover {
  transform: scale(1.1);

}

nav {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  height: 10vh;
  z-index: 100;
  position: sticky;
}

.midleNav {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

.Nav-Icon {
  display: none;
}

#talk .Menu-Items {
  display: none;
}

/* Switch drak and light */

.toggle-switch {
  position: relative;
  width: 200px;
  height: 50%;
  transform: scale(0.9);
}

.toggle-switch label {
  position: absolute;
  width: 50%;
  height: 50px;
  background-color: couleurs.$darkcolor;
  border-radius: 50px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  display: none;
}

.toggle-switch .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: 0.3s;
}

.toggle-switch input:checked~.slider {
  background-color: couleurs.$lightcolor;
}

.toggle-switch .slider::before {
  content: "";
  position: absolute;
  top: 6.5px;
  left: 8px;
  width: 37.5px;
  height: 37.5px;
  border-radius: 50%;
  box-shadow: inset 14px -2px 0px 0px couleurs.$lightcolor;
  background-color: var(--dark);
  transition: 0.3s;
}

.toggle-switch input:checked~.slider::before {
  transform: translateX(45px);
  background-color: couleurs.$darkcolor;
  box-shadow: none;
}

@media screen and (max-width: 1280px) {
  nav {
    width: 100%;
    justify-content: space-around;
  }

  #talk {
    font-size: 100%;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .toggle-switch {
    transform: scale(0.8vw);
    /*  pour que mon transform soit responsive en largeur */
    transform: scale(0.8 * 1vw);

  }

  .midleNav li {
    font-size: 95%;
  }

  .toggle-switch {
    width: 100px;
  }

  .toggle-switch label {
    width: 100%;

  }


}

@media screen and (max-width: 780px) {

  /* dark and light */
  .nav-light nav {
    background: couleurs.$whitecolor;
  }

  .nav-dark nav {
    background: couleurs.$darkcolor;
  }

  .nav-dark nav img {
    filter: invert(1);
  }

  /* on adapte la size de la nav a au format mobile */
  nav {
    width: 100%;

    justify-content: center;
    gap: 13vw;
  }

  nav .midleNav {
    gap: 13vw;
  }


  /* on fait disparaitre les textes et on affiche les icons */
  nav .midleNav span {
    display: none;
  }

  nav #talk span {
    display: none;
  }

  .Nav-Icon {
    display: block;
    width: 7vw;
  }

  /* on fait disparaitre le bouton lets talk et on affiche la cloche */
  #talk .Menu-Items {
    display: block;
  }

  #talk {
    padding: 0;
    box-shadow: none;
    background: none;
  }

  #talk:hover {
    transform: none;
  }

  /* togle switch responsive for smartphone */
  .toggle-switch {
    transform: scale(1);
    width: 9vw;
    height: 7vw;
  }

  .toggle-switch label {
    width: 9vw;
    height: 6.5vw;
  }

  .toggle-switch .slider {
    width: 9vw;
    height: 6.5vw;
  }

  .toggle-switch .slider::before {
    top: 1vw;
    left: 1vw;
    width: 4.5vw;
    height: 4.5vw;
    box-shadow: inset 1.7vw -0.15vw 0px 0px couleurs.$lightcolor;
  }

  .toggle-switch input:checked~.slider::before {
    transform: translateX(2.5vw);
  }










}