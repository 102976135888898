// src/Components/Modal/SafariModal.scss
.safari-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.safari-modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
}

button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    margin-top: 1rem;
}